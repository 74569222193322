export const UserRole = {
  Master: 'mestre',
  Franchisee: 'franqueado',
  Iniciante: 'iniciante',
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'pt', name: 'Portugues Brasil', direction: 'ltr' },
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyAf83SViRzOacTaaKjlbzEz1480BOjjbFE',
  authDomain: 'stafebank-uc.firebaseapp.com',
  projectId: 'stafebank-uc',
  storageBucket: 'stafebank-uc.appspot.com',
  messagingSenderId: '808096375011',
  appId: '1:808096375011:web:0ce44e5dce13ee75b2e5d6',
  measurementId: 'G-DPYZCYCHB7',
};

export const adminRoot = '/app';
export const buyUrl = '';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueyale';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
