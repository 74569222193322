/* eslint-disable */
export const ACTION = {
  ADD: 'USERS_ADD',
  SET: 'USERS_SET',
};

const INITIAL_STATE = [];

const userSwitcher = {
  [ACTION.ADD]: (state, action) => action.payload.map(user => state.find(u => u.uid == user.uid)?{...state.find(u => u.uid == user.uid), ...user}:user),
  [ACTION.SET]: (state, action) => state.map(
    user => user.uid === action.payload.uid?{...user, ...action.payload}:user
  ),
  'default': (state, action) => state,
};

export default (state = INITIAL_STATE, action) => (userSwitcher[action.type] || userSwitcher['default'])(state, action);