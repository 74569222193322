/* eslint-disable */
export const ACTION = {
  ADD: 'NOTIFICATIONS_ADD',
  SET: 'NOTIFICATIONS_SET',
};

const INITIAL_STATE = [];

const notificationSwitcher = {
  [ACTION.ADD]: (state, action) => action.payload.map(notification => state.find(note => note.key == notification.key)?{...state.find(note => note.key == notification.key), ...notification}:notification),
  [ACTION.SET]: (state, action) => state.map(
    notification => notification.key === action.payload.key?{...notification, ...action.payload}:notification
  ),
  'default': (state, action) => state,
};

export default (state = INITIAL_STATE, action) => (notificationSwitcher[action.type] || notificationSwitcher['default'])(state, action);