import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import { firebaseConfig } from 'constants/defaultValues';

const fb = firebase.initializeApp(firebaseConfig);

const secondaryFb = firebase.initializeApp(firebaseConfig, 'administration');

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

export { auth, firestore, storage, functions, secondaryFb, fb };
export default firebase;
